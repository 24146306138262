// Generated by Framer (508aa67)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["rlF9ghWhN"];

const serializationHash = "framer-wdSsu"

const variantClassNames = {rlF9ghWhN: "framer-v-95b8q7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "rlF9ghWhN", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-95b8q7", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"rlF9ghWhN"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1jxoil8"} data-framer-name={"oui:arrow-up"} layout={"position"} layoutDependency={layoutDependency} layoutId={"GriaTILys"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 16 17\"><path d=\"M 13.069 5.657 L 8.384 10.268 C 8.282 10.369 8.144 10.426 8 10.426 C 7.856 10.426 7.718 10.369 7.616 10.268 L 2.931 5.658 C 2.828 5.557 2.69 5.5 2.545 5.5 C 2.401 5.5 2.263 5.557 2.16 5.658 C 2.109 5.707 2.069 5.766 2.042 5.831 C 2.014 5.897 2 5.967 2 6.037 C 2 6.108 2.014 6.178 2.042 6.243 C 2.069 6.308 2.109 6.367 2.16 6.417 L 6.844 11.027 C 7.152 11.33 7.568 11.499 8 11.499 C 8.432 11.499 8.847 11.33 9.156 11.027 L 13.84 6.417 C 13.891 6.367 13.931 6.308 13.959 6.243 C 13.986 6.178 14.001 6.108 14.001 6.037 C 14.001 5.966 13.986 5.896 13.959 5.831 C 13.931 5.765 13.891 5.706 13.84 5.657 C 13.737 5.556 13.599 5.499 13.454 5.499 C 13.31 5.499 13.172 5.556 13.069 5.657 Z\" fill=\"rgb(0,0,0)\"></path></svg>"} svgContentId={3308027900} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wdSsu.framer-1ap5svd, .framer-wdSsu .framer-1ap5svd { display: block; }", ".framer-wdSsu.framer-95b8q7 { height: 17px; overflow: hidden; position: relative; width: 16px; }", ".framer-wdSsu .framer-1jxoil8 { flex: none; height: 17px; left: calc(50.00000000000002% - 16px / 2); position: absolute; top: calc(47.05882352941179% - 17px / 2); width: 16px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 17
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerkz_o5zftw: React.ComponentType<Props> = withCSS(Component, css, "framer-wdSsu") as typeof Component;
export default Framerkz_o5zftw;

Framerkz_o5zftw.displayName = "Nav/dropdown";

Framerkz_o5zftw.defaultProps = {height: 17, width: 16};

addFonts(Framerkz_o5zftw, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})